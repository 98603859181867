<template>
  <div></div>
</template>

<script>
import { userManager } from "../api/auth";

export default {
  created() {
    const authenticate = async () => {
      try {
        await userManager.signinRedirectCallback();
        this.$router.push({ name: "extraction" });
      } catch (ex) {
        await userManager.signinRedirect();
      }
    };
    authenticate();
  }
};
</script>
