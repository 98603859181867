<template>
  <v-btn type="button" @click="onClick" color="primary">
    <input
      :accept="accept"
      ref="fileInput"
      type="file"
      style="display: none"
      @input="$emit('input', $event)"
    />
    <template v-slot:prepend>
      <v-icon :icon="iconName"></v-icon>
    </template>
    <slot></slot>
  </v-btn>
</template>

<script setup>
import { ref } from "vue";

defineProps(["accept", "iconName"]);
const fileInput = ref(null);

const onClick = () => {
  fileInput.value.click();
};

</script>
