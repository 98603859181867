<template>
  <table-wrapper
    title="Mapas de Extracción"
    subtitle="Para generar un mapa de extracción, selecciona el rango de fechas y presiona 'Generar mapa'. Adicionalmente, la primera fila de la tabla corresponde al mapa de extracción histórico y se genera automáticamente cuando se sube un nuevo reporte."
  >
    <template #actions>
      <div class="d-flex justify-end align-center">
        <div class="d-flex mr-6">
          <v-menu
            :close-on-content-click="false"
            v-model="openPickerMenu"
            location="bottom"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                style="min-width: 255px"
                v-bind="props"
                variant="outlined"
                class="w-50"
                hide-details
                label="Rango de fechas"
                :model-value="
                  startDate && endDate
                    ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                    : ''
                "
              >
                <template #prepend-inner>
                  <fa-icon
                    icon="calendar-week"
                    class="mr-2"
                    style="color: #9e9e9e"
                  ></fa-icon>
                </template>
              </v-text-field>
            </template>
            <div class="elevation-2 z-index:99 mt-1">
              <div class="d-flex">
                <v-date-picker
                  title="Desde"
                  class="pb-2 bg-white"
                  v-model="startDate"
                ></v-date-picker>
                <v-divider vertical></v-divider>
                <v-date-picker
                  title="Hasta"
                  class="pb-2 bg-white"
                  v-model="endDate"
                ></v-date-picker>
              </div>
            </div>
          </v-menu>
        </div>
        <v-btn
          size="large"
          color="primary"
          @click="onGenerateMaps"
          :disabled="!(startDate && endDate)"
        >
          Generar mapa
        </v-btn>
      </div>
    </template>
    <template #table>
      <v-table>
        <thead>
          <tr>
            <th scope="col">Rango de fechas</th>
            <th scope="col">Estado</th>
            <th scope="col">Creado</th>
            <th scope="col">Modificado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td class="text-center" colspan="5">Cargando...</td>
          </tr>
          <tr v-if="!isLoading && reports.length === 0">
            <td class="text-center" colspan="5">
              No se han generado mapas de extración hasta el momento.
            </td>
          </tr>
          <tr v-show="!isLoading" v-for="report in reports" :key="report.date">
            <td v-if="!report.start && !report.end">Histórico</td>
            <td v-else-if="!report.start && report.end">
              Histórico - {{ formatDate(report.end) }}
            </td>
            <td v-else>
              {{ formatDate(report.start) }} - {{ formatDate(report.end) }}
            </td>
            <td>
              <div class="d-flex ga-2">
                {{ statusNames[report.status] }}
                <popover
                  v-if="report.errors?.length > 0"
                  title="Información del error"
                >
                  <template v-slot:toggle>
                    <v-icon
                      size="x-small"
                      icon="fas fa-circle-question"
                      color="error"
                    ></v-icon>
                  </template>
                  <template v-slot:content>
                    <div>
                      <ul class="mb-2">
                        <li v-for="error in report.errors" :key="error">
                          <small>{{ error }}</small>
                        </li>
                      </ul>
                    </div>
                  </template>
                </popover>
              </div>
            </td>
            <td>
              {{ new Date(`${report.created}Z`).toLocaleString() }}
            </td>
            <td>
              {{ new Date(`${report.updated}Z`).toLocaleString() }}
            </td>
            <td class="d-flex align-center">
              <div v-if="report.status === 'COMPLETED'" class="d-flex">
                <button-table-action
                  :aria-disabled="disableDownload"
                  @click="downloadMap(report)"
                >
                  <v-icon icon="fas fa-download"></v-icon>
                </button-table-action>
              </div>
              <div v-if="report.start != null && report.end != null">
                <button-table-action
                  @click="deleteMap(report.start, report.end)"
                >
                  <v-icon icon="fas fa-trash-alt"></v-icon>
                </button-table-action>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div class="mt-3">
        <v-pagination
          :total="pagesCount"
          :total-visible="5"
          v-model="page"
        ></v-pagination>
      </div>
    </template>
  </table-wrapper>
</template>

<script setup>
import { ref, watch } from "vue";

import TableWrapper from "@/components/TableWrapper.vue";
import ButtonTableAction from "@/components/shared/Button/ButtonTableAction.vue";
import { downloadMap as downloadMapApi } from "../api/reports";
import { deleteMap as deleteMapApi } from "../api/maps";
import { listMaps, generateMap } from "../api/maps";
import { formatDateInTable as formatDate } from "@/utils/date";

const reports = ref([]);
const page = ref(1);
const pageSize = 30;
const pagesCount = ref(0);
const isLoading = ref(false);
const disableDownload = ref(false);
const openPickerMenu = ref(false);
const startDate = ref(null);
const endDate = ref(null);

const statusNames = {
  PENDING: "Pendiente...",
  PROCESSING: "Procesando...",
  COMPLETED: "Completado",
  FAILED: "Fallido",
};

async function onGenerateMaps() {
  if (openPickerMenu.value) {
    openPickerMenu.value = false;
  }
  try {
    await generateMap({ startDate: startDate.value, endDate: endDate.value });
    startDate.value = null;
    endDate.value = null;
  } catch (err) {
    console.log(err);
  }
}

const downloadMap = async (report) => {
  disableDownload.value = true;
  const response = await downloadMapApi({
    startDate: report.start,
    endDate: report.end,
  });
  try {
    const url = await response.json();
    const a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } finally {
    disableDownload.value = false;
  }
};

async function getMaps() {
  isLoading.value = true;
  try {
    const response = await listMaps(page.value, pageSize);
    const { results: maps, count } = await response.json();
    pagesCount.value = count;
    reports.value = maps.map((report) => {
      return {
        name: report.filename,
        created: report.created,
        updated: report.updated,
        status: report.status,
        start: report.start_date,
        end: report.end_date,
      };
    });
  } catch (err) {
    console.log(err);
  } finally {
    isLoading.value = false;
  }
}

watch(page, getMaps, { immediate: true });

const deleteMap = async (startDate, endDate) => {
  try {
    await deleteMapApi({ startDate, endDate });
    getMaps();
  } catch (err) {
    console.log(err);
  }
};
</script>
