<template>
  <v-toolbar color="surface" :border="true" height="80">
    <div class="position-relative ml-3">
      <a href="#" class="logo">
        <img src="../../assets/logo.png" />
      </a>
    </div>
    <v-spacer></v-spacer>
    <div class="d-flex ga-1 mr-3">
      <v-btn href="/performance" variant="text" class="text-medium-emphasis">Rendimientos</v-btn>
      <v-btn href="/extraction" variant="text" class="text-medium-emphasis">Reportes</v-btn>
      <v-btn href="/maps" variant="text" class="text-medium-emphasis">Mapas</v-btn>
      <v-btn v-if="isAuthenticated" @click="logout" variant="text" class="text-medium-emphasis">
        Cerrar sesión
        <template v-slot:append>
          <v-icon icon="fas fa-right-from-bracket"></v-icon>
        </template>
      </v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import { isAuthenticated } from "../../api/auth";

export default {
  data() {
    return {
      isAuthenticated: false
    };
  },
  created() {
    isAuthenticated().then(isAuth => (this.isAuthenticated = isAuth));
  },
  methods: {
    logout() {
      this.$router.push({ name: "logout" });
    }
  }
};
</script>

<style scoped>
.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.logo img {
  height: 77px;
}
</style>