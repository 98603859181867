import { createApp } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileExcel,
  faMap,
  faTrash,
  faEdit,
  faMapMarkedAlt,
  faFileCsv,
  faSignOutAlt,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faChevronDown,
  faCloudDownloadAlt,
  faSearch,
  faThumbsUp,
  faExclamationCircle,
  faThumbsDown,
  faDrawPolygon,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faCloudUploadAlt,
  faCalendarWeek,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "vuetify/styles";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/vue-fontawesome";
import Popover from "./components/shared/Popover.vue";
import router from "./router.js";
import vuetify from "./plugins/vuetify";

library.add(faFileExcel);
library.add(faMap);
library.add(faTrash);
library.add(faEdit);
library.add(faMapMarkedAlt);
library.add(faFileCsv);
library.add(faSignOutAlt);
library.add(faUpload);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faQuestionCircle);
library.add(faCloudDownloadAlt);
library.add(faCloudUploadAlt);
library.add(faSearch);
library.add(faThumbsUp);
library.add(faExclamationCircle);
library.add(faThumbsDown);
library.add(faDrawPolygon);
library.add(faTimes);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faCalendarWeek);
library.add(faDownload);

const app = createApp(App).use(router);

app.component("fa-icon", FaIcon);
app.component("popover", Popover);
app.config.productionTip = false;
app.use(vuetify).mount("#app");
