import { formatDate } from "@/utils/date";
import { makeRequest, makeFormRequest } from "./utils";

export const listReports = async ({ year, month }) => {
  let url = "reports";
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const start = formatDate(startDate);
  const end = formatDate(endDate);
  
  if (year != null && month != null) {
    url += `?start=${start}&end=${end}`;
  }
  return await makeRequest(url);
};

export const deleteReport = async ({ date }) => {
  let url = `reports/${date}`;
  return await makeRequest(url, { method: "DELETE" });
};

export const uploadReport = async ({
  date,
  file,
  filename,
  // previousExtractionFile
}) => {
  const uploadURL = `reports`;
  const form = new FormData();
  form.append("date", date);
  form.append("filename", filename);
  form.append("file", file);

  return await makeFormRequest(uploadURL, { method: "POST", data: form });
};

export const downloadReport = async ({ reportDate }) => {
  let url = `reports/${reportDate}/file`;
  return await makeRequest(url);
};

export const downloadMap = async ({ startDate, endDate }) => {
  let url = `maps/file`;
  const params = [];
  if (startDate != null) {
    params.push(`start_date=${startDate}`);
  }
  if (endDate != null) {
    params.push(`end_date=${endDate}`);
  }
  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return await makeRequest(url);
};

