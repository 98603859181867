<template>
  <v-sheet>
    <TheNavbar />
    <v-container>
      <router-view />
      <v-snackbar
        v-model="showAlert"
        :color="typeToColor[alertSetup.type] ?? 'primary'"
        :timeout="alertSetup.timeout"
      >
        {{ alertSetup.msg }}

        <template v-slot:actions>
          <v-btn color="#FFF" icon @click="showAlert = false" density="compact">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-sheet>
</template>

<script setup>
import { provide, ref } from "vue";
import TheNavbar from "./components/shared/TheNavbar.vue";

const showAlert = ref(false);
const alertSetup = ref({ msg: "", type: "info", timeout: -1 });

const typeToColor = {
  info: "primary",
  error: "#fb8a00",
  success: "#4caf50",
};

const alert = (msg, type, timeout = -1) => {
  showAlert.value = true;
  alertSetup.value = { msg, type, timeout };
};
provide("alert", alert);
</script>
