<template>
  <table-wrapper
    title="Reportes de Rendimiento"
    subtitle="Contiene la lista de los reportes cut/fill en formato HTML. Para cargar un reporte nuevo, haga click en 'Subir reporte' y seleccione el archivo HTML.
    Para descargar el reporte de rendimiento a la fecha, presione el botón de descarga al lado de 'Subir reporte'."
  >
    <template #actions>
      <v-container>
        <v-row v-if="!loadingIsDriveAuthenticated && !isDriveAuthenticated">
          <v-col class="d-flex align-center">
            <v-icon
              icon="fas fa-solid fa-circle-exclamation"
              color="warning"
            ></v-icon>
            <p class="ml-2 text-subtitle-1">
              The system is not connected to Google Drive.
            </p>
            <v-btn color="warning" class="ml-5" @click="handleAuthClick">
              Authenticate
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="space-between" align="center">
          <v-col cols="6" sm="5" md="4">
            <v-menu
              :close-on-content-click="false"
              v-model="openPickerMenu"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  :model-value="`${months[month]} de ${year}`"
                  variant="outlined"
                  hide-details
                  label="Selecciona el mes"
                >
                  <template #prepend-inner>
                    <fa-icon
                      icon="calendar-week"
                      class="mr-2"
                      style="color: #9e9e9e"
                    ></fa-icon>
                  </template>
                </v-text-field>
              </template>
              <div class="d-flex elevation-2 z-index:99 mt-1">
                <v-date-picker-months
                  class="pb-2 bg-white"
                  @update:model-value="openPickerMenu = false"
                  v-model="month"
                  color="white"
                ></v-date-picker-months>
                <v-divider vertical></v-divider>
                <v-date-picker-years
                  class="pb-2 bg-white"
                  v-model="year"
                  min="2015-1"
                  :max="`${now.getFullYear()}-${now.getMonth()}`"
                ></v-date-picker-years>
              </div>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="7" md="8" class="d-flex justify-end">
            <v-btn
              class="mr-2"
              color="primary"
              size="large"
              style="min-width: 0"
              @click="onDownloadPerformanceReport"
              :disabled="disableDownload"
            >
              <fa-icon icon="download"></fa-icon>
            </v-btn>
            <v-btn
              color="primary"
              size="large"
              :disabled="!isDriveAuthenticated"
              @click="onCreateReportClick"
            >
              <input
                accept="text/html"
                ref="fileInput"
                type="file"
                style="display: none"
                @input="onSelectCutFillFile"
              />
              + Subir reporte
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #table>
      <v-table>
        <thead>
          <tr>
            <th id="filename">Nombre del reporte</th>
            <th id="date">Fecha</th>
            <th id="status">Estado</th>
            <th id="created">Creado</th>
            <th id="actions">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td class="text-center" colspan="5">Cargando...</td>
          </tr>
          <tr v-if="!isLoading && reports.length === 0">
            <td class="text-center" colspan="5">
              No se han generado mapas de extración hasta el momento.
            </td>
          </tr>
          <tr v-show="!isLoading" v-for="report in reports" :key="report.date">
            <td>
              {{ report.filename }}
            </td>
            <td>
              {{ formatDate(report.date) }}
            </td>
            <td>
              <div class="d-flex ga-2">
                {{ report.errors?.length > 0 ? "Falló" : "Completado" }}
                <popover
                  v-if="report.errors?.length > 0"
                  title="Información del error"
                >
                  <template v-slot:toggle>
                    <v-icon
                      size="x-small"
                      icon="fas fa-circle-question"
                      color="error"
                    ></v-icon>
                  </template>
                  <template v-slot:content>
                    <div>
                      <ul class="mb-2">
                        <li v-for="error in report.errors" :key="error">
                          <small>{{ error }}</small>
                        </li>
                      </ul>
                    </div>
                  </template>
                </popover>
              </div>
            </td>
            <td>
              {{ new Date(`${report.created}Z`).toLocaleString() }}
            </td>
            <td>
              <div class="d-flex">
                <button-table-action
                  :disabled="disableDownload"
                  @click="onDownloadCutFillReport(new Date(report.date))"
                >
                  <v-icon icon="fas fa-download"></v-icon>
                </button-table-action>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </template>
  </table-wrapper>
</template>

<script setup>
import { ref, onMounted, watch, inject } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import ButtonTableAction from "@/components/shared/Button/ButtonTableAction.vue";
import { getIsDriveAuthenticated, authorize } from "@/api/store";
import { formatDateInTable as formatDate } from "@/utils/date";
import {
  listCutReports,
  uploadCutReport,
  downloadPerformanceReport,
  downloadCutReport,
} from "@/api/performance";

const isDriveAuthenticated = ref(false);
const loadingIsDriveAuthenticated = ref(false);

const reports = ref([]);
const isLoading = ref(false);
const disableDownload = ref(false);

const fileInput = ref(null);

const onCreateReportClick = () => {
  fileInput.value.click();
};

const handleAuthClick = async () => {
  await authorize();
};

const now = new Date();
const year = ref(now.getFullYear());
const month = ref(now.getMonth());

const getReports = async () => {
  isLoading.value = true;
  const start = new Date(year.value, month.value, 1);
  const end = new Date(year.value, month.value + 1, 0);

  const response = await listCutReports(start, end);
  reports.value = await response.json();
  isLoading.value = false;
};

watch([month, year], () => getReports());

onMounted(() => {
  loadingIsDriveAuthenticated.value = true;
  getIsDriveAuthenticated()
    .then((isAuthenticated) => {
      isDriveAuthenticated.value = isAuthenticated;
    })
    .finally(() => {
      loadingIsDriveAuthenticated.value = false;
    });
  getReports();
});

const alert = inject("alert");

const onSelectCutFillFile = async (event) => {
  if (event.target.files?.length === 0) {
    return;
  }
  try {
    alert("Subiendo reporte, por favor no recargue la página...", "info");
    const response = await uploadCutReport(event.target.files[0]);
    if (response.ok) {
      alert("Reporte cargado y procesado satisfactoriamente", "success", 5000);
      getReports();
    } else {
      console.log("Here");
      alert("Error al cargar el reporte", "error", 5000);
    }
  } catch (error) {
    console.error(error);
    alert("Error al cargar el reporte", "error", 5000);
  }
};

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const openPickerMenu = ref(false);

const onDownloadPerformanceReport = async () => {
  disableDownload.value = true;
  try {
    const response = await downloadPerformanceReport();
    if (!response.ok) {
      return;
    }
    const data = await response.blob();
    const a = document.createElement("a");
    a.href = URL.createObjectURL(data);
    const filename = response.headers.get("Content-Disposition").split("=")[1];
    a.setAttribute("download", filename);
    a.click();
  } finally {
    disableDownload.value = false;
  }
};

const onDownloadCutFillReport = async (date) => {
  disableDownload.value = true;
  try {
    const response = await downloadCutReport(date);
    if (!response.ok) {
      return;
    }
    const data = await response.blob();
    const a = document.createElement("a");
    a.href = URL.createObjectURL(data);
    const filename = response.headers.get("Content-Disposition").split("=")[1];
    a.setAttribute("download", filename);
    a.click();
  } finally {
    disableDownload.value = false;
  }
};
</script>
