import { makeRequest } from './utils';

export const DRIVE_AUTH_STATUS = {
    AUTHENTICATED: 'authenticated',
    NOT_AUTHENTICATED: 'not_authenticated',
    UNKNOWN: 'unknown',
};

export const getIsDriveAuthenticated = async () => {
    const url = 'stores/drive/is-authorized';
    const response = await makeRequest(url);
    const { isAuthorized } = await response.json();

    return isAuthorized;
};

export const listMaps = async (page, pageSize) => {
    let url = 'maps';
    url += `?page=${page}&page_size=${pageSize}`;
    return await makeRequest(url);

    // const results = [
    //     {
    //         filename: 'Reporte2024-01-01.xlsx',
    //         date: '2024-01-01',
    //         created_at: '2024-01-30 21:09:25',
    //         status: 1,
    //     },
    //     {
    //         start_date: '2024-01-02',
    //         end_date: '2024-02-02',
    //         filename: 'Reporte2024-01-02.xlsx',
    //         date: '2024-01-02',
    //         created_at: '2024-02-01 09:29:11',
    //         status: 2,
    //     },
    //     {
    //         start_date: '2024-01-02',
    //         end_date: '2024-02-02',
    //         filename: 'Reporte2021-11-08.xlsx',
    //         date: '2024-01-24',
    //         created_at: '2024-02-01 09:30:18',
    //         status: 2,
    //     },
    // ];

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({ ok: true, json: async () => ({ results, count: 2 }) });
    //     }, 3000);
    // });
};

export const authorize = async () => {
    const url = 'stores/drive/authorize';
    console.log(url);
    // not follow the redirect
    try {
        const response = await makeRequest(url);
        const { url: oauthUrl } = await response.json();
        window.location.href = oauthUrl;
    } catch (error) {
        console.log(error);
    }
    // return await fetch("http://localhost:3000/store/drive/authorization-url")
};
