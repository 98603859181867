<template>
  <v-container class="container">
    <slot></slot>
  </v-container>
</template>

<style scoped>
.container {
  min-width: 576px;
}
</style>
