import { formatDate } from "@/utils/date";
import { makeRequest } from "./utils";

export const listMaps = async (page, pageSize) => {
  return await makeRequest(`maps?page=${page}&page_size=${pageSize}`);
};

export const generateMap = async ({ startDate, endDate }) => {
  let url = "maps";

  const data = {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
  return await makeRequest(url, { method: "POST", data });
};

export const deleteMap = async ({ startDate, endDate }) => {
  let url = "maps";
  const params = [];
  if (startDate != null) {
    params.push(`start_date=${startDate}`);
  }
  if (endDate != null) {
    params.push(`end_date=${endDate}`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return await makeRequest(
    `${url}?start_date=${startDate}&end_date=${endDate}`,
    { method: "DELETE" }
  );
};
