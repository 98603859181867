import { getUser } from './auth';

const isDebug = process.env.NODE_ENV === 'development';
const baseURL = isDebug ? 'http://localhost:9999/api' : '/api';

export const getUrl = (path) => {
    return `${baseURL}/${path}`;
};

export const makeRequest = async (
    path,
    { method = 'GET', data, ...extra } = {}
) => {
    const params = { ...extra };
    if (data != null) {
        params.body = JSON.stringify(data);
    }
    const user = await getUser();

    return await fetch(`${baseURL}/${path}`, {
        method,
        headers: {
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
        },
        ...params,
    });
};

export const makeFormRequest = async (path, { method = 'GET', data } = {}) => {
    const user = await getUser();
    return await fetch(`${baseURL}/${path}`, {
        method,
        headers: {
            Authorization: `Bearer ${user.access_token}`,
        },
        body: data,
    });
};
