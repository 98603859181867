import { createRouter, createWebHistory } from "vue-router";
import { isAuthenticated } from "./api/auth";
import PageExtractionMaps from "./pages/PageExtractionMaps.vue";
import PageLogin from "./pages/PageLogin.vue";
import PageLogout from "./pages/PageLogout.vue";
import PageMapsVue from "./pages/PageMaps.vue";
import PagePerformanceVue from "./pages/PagePerformance.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "extraction" },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/extraction",
    name: "extraction",
    component: PageExtractionMaps,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/maps",
    name: "maps",
    component: PageMapsVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/performance",
    name: "performance",
    component: PagePerformanceVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: PageLogin,
  },
  {
    path: "/logout",
    name: "logout",
    component: PageLogout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuth = await isAuthenticated();
    if (!isAuth) {
      next({
        path: "/login",
      });
      return;
    }
  }
  next();
});

export default router;
