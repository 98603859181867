<template>
  <modal max-width="600px" :show="show" @toggle="$emit('toggle')">
    <template v-slot:header> Creación de reporte </template>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <v-text-field
          label="Fecha de inicio del turno"
          id="startDate"
          variant="outlined"
          type="text"
          v-model="startDate"
          @focus="delete errors.startDate"
          placeholder="AAAA-MM-DD"
        ></v-text-field>
        <v-text-field
          label="Nombre del archivo"
          variant="outlined"
          id="filename"
          type="text"
          v-model="filename"
          @focus="delete errors.file"
          :rules="[() => !errors.file || errors.file]"
        ></v-text-field>
        <button-upload-file
          class="mt-3 mb-3"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @input="onSelectFile"
          iconName="fas fa-upload"
        >
          Seleccionar reporte
        </button-upload-file>
        <!-- <button-upload-file
          class="mt-3 mb-3"
          accept=".dxf"
          @input="onSelectPreviousExtraction"
          iconName="fas fa-draw-polygon"
        >
          Dragado anterior
        </button-upload-file> -->
        <div class="extraction-file" v-if="file">
          {{ filename }}
          <fa-icon @click="file = null" icon="times"></fa-icon>
        </div>
        <!-- <div class="extraction-file" v-if="previousExtractionFile">
          {{ previousExtractionFile.name }}
          <fa-icon
            @click="previousExtractionFile = null"
            icon="times"
          ></fa-icon>
        </div> -->
        <span style="font-size: 12px">
          1. El archivo debe ser de tipo xlsx.
          <br />
          2. El archivo debe contener por lo menos las columnas de la siguiente
          <a @click.prevent="onClickTemplateLink" href="#">plantilla</a>.
        </span>
      </div>
    </template>
    <template v-slot:footer>
      <v-btn @click="onCancel"> Cancelar </v-btn>
      <v-btn @click="onCreateReport" color="primary"> Crear </v-btn>
    </template>
  </modal>
</template>

<script>
import XLSX from "xlsx";
import Modal from "../../components/shared/Modal/Modal.vue";
import ButtonUploadFile from "../shared/Button/ButtonUploadFile.vue";
import { formatDate, excelNumberToDate } from "../../utils/date";
import { uploadReport } from "../../api/reports";
import { getUrl } from "@/api/utils";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle", "created"],
  components: {
    Modal,
    ButtonUploadFile,
  },
  data() {
    return {
      startDate: formatDate(new Date()),
      filename: "",
      file: null,
      previousExtractionFile: null,
      errors: {},
    };
  },
  methods: {
    onSelectFile(event) {
      if (event.target.files?.length > 0) {
        let reader = new FileReader();
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target.result, { type: "binary" });
          const range = XLSX.utils.sheet_to_json(workbook.Sheets.Reporte, {
            range: "A1:A2",
          });
          if (range.length > 0) {
            let date = range[0].FECHA;
            if (typeof date === "number") {
              date = excelNumberToDate(date).toISOString().slice(0, 10);
            } else if (typeof date === "string") {
              date = formatDate(new Date(date));
            } else {
              return;
            }
            if (!Date.parse(date)) {
              this.errors.startDate =
                "Excel no pudo reconocer la fecha en la primera fila. Revise el formato en el archivo";
              return;
            }
            delete this.errors.startDate;
            this.startDate = date;
            this.filename = `Reporte${date}.xlsx`;
            this.file = event.target.files[0];
          }
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    },
    onSelectPreviousExtraction(event) {
      if (event.target.files?.length > 0) {
        this.previousExtractionFile = event.target.files[0];
      }
    },
    validate() {
      const errors = {};
      if (!Date.parse(this.startDate)) {
        errors.startDate =
          "Fecha inicial inválida. Debe tener el formato YYYY-MM-DD";
      }
      if (!this.file) {
        errors.file = "Es necesario seleccionar un archivo con un tipo válido";
      }
      if (!this.filename) {
        errors.file = "El nombre de archivo no puede ser vacío";
      }
      this.errors = errors;
      return errors;
    },
    reset() {
      this.startDate = formatDate(new Date());
      this.filename = "";
      this.file = null;
      this.errors = {};
      this.previousExtractionFile = null;
    },
    onCreateReport() {
      this.validate();
      if (!this.isValid) {
        return;
      }
      const newReport = {
        date: this.startDate,
        file: this.file,
        filename: this.filename,
        // previousExtractionFile: this.previousExtractionFile
      };
      const response = uploadReport(newReport);
      if (response.status === 200) {
        this.$emit("created", newReport);
      }
      this.$emit("toggle");
    },
    onCancel() {
      this.$emit("toggle");
      this.reset();
    },
    onClickTemplateLink() {
      const clickLink = async () => {
        // const response = await downloadTemplate();
        // if (!response.ok) {
        //   return;
        // }
        const url = getUrl("templates/extraction");
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      clickLink();
    },
  },
  computed: {
    isValid() {
      return Object.keys(this.errors).length === 0;
    },
  },
};
</script>

<style scoped>
.error-message {
  font-size: 12px;
  color: #ff0000;
}

.extraction-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #eee;
  height: 30px;
  margin: 0.2rem 0;
  padding: 0rem 0.7rem;
  border-radius: 15px;
  color: #777;
}

.extraction-file > svg {
  color: #999;
}
</style>
