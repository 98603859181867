import { UserManager } from "oidc-client";


const domainPrefix = process.env.VUE_APP_COGNITO_DOMAIN_PREFIX;
const userPoolId = process.env.VUE_APP_USER_POOL_ID;
const region = process.env.VUE_APP_REGION;
const client_id = process.env.VUE_APP_APP_CLIENT_ID;
const currentURL = window.location;
const redirect_uri = encodeURI(
  `${currentURL.protocol}//${currentURL.host}/login`
);
const authority = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
export const userManager = new UserManager({
  authority: `https://${authority}`,
  client_id,
  redirect_uri,
  response_type: "code",
  post_logout_redirect_uri: redirect_uri,
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: true,
});

export const logout = async () => {
  window.location = `https://${domainPrefix}.auth.${region}.amazoncognito.com/logout?client_id=${client_id}&logout_uri=${redirect_uri}`;
};

let user = null;

export const getUser = async () => {
  if (user == null) {
    user = await userManager.getUser();
  }
  if (user?.expired) {
    user = await userManager.signinSilent();
  }
  return user;
};

export const isAuthenticated = async () => {
  let user = await getUser();
  return user?.access_token && !user.expired;
};
