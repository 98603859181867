import { formatDate } from "@/utils/date";
import { makeRequest, makeFormRequest } from "./utils";

export const listCutReports = async (start, end) => {
  return await makeRequest(
    `cut-reports?start=${formatDate(start)}&end=${formatDate(end)}`
  );
};

export const uploadCutReport = async (file) => {
  const uploadURL = `cut-reports`;
  const form = new FormData();
  form.append("file", file);

  return await makeFormRequest(uploadURL, { method: "POST", data: form });
};

export const downloadPerformanceReport = async () => {
  let url = `performance-report`;
  return await makeRequest(url);
};

export const downloadCutReport = async (date) => {
  let url = `cut-reports/${formatDate(date)}/file`;
  return await makeRequest(url);
};
