<template>
  <modal max-width="500px" :show="show" @toggle="$emit('toggle')">
    <template v-slot:header>
      Eliminar reporte {{ report?.date }}
    </template>
    <template v-slot:body>
      Está a punto de eliminar el reporte con fecha {{ report?.date }}.
      Todos los datos asociados, incluyendo las estadísticas y los mapas
      serán eliminados permanentemente. Está seguro que desea continuar?
    </template>
    <template v-slot:footer>
      <v-btn @click="$emit('toggle')">
        Cancelar
      </v-btn>
      <v-btn @click="$emit('accept')" color="primary">
        Aceptar
      </v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "../../components/shared/Modal/Modal.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    report: {
      type: Object
    }
  },
  emits: ["toggle", "accept"],
  components: {
    Modal,
  }
};
</script>
