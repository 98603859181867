export const formatDate = date => {
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  if (parseInt(month) < 10) {
    month = `0${month}`;
  }
  let day = date.getDate().toString();
  if (parseInt(day) < 10) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
};

export const formatTime = datetime => {
  const values = [datetime.getHours(), datetime.getMinutes()];
  return values
    .map(value => {
      if (value < 10) {
        return `0${value}`;
      }
      return value.toString();
    })
    .join(":");
};

export const excelNumberToDate = value => {
  return new Date(-2209075200000 + (value - (value < 61 ? 0 : 1)) * 86400000);
};

export function formatDateInTable(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${day}/${month}/${year}`;
}
