<template>
  <div></div>
</template>

<script>
import { logout } from "../api/auth";

export default {
  created() {
    logout();
  }
};
</script>
